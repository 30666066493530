/**
 * @param {handleSearch|main} callback
 * @param {string[]} events
 */
export function DOMReady(callback, events) {
  if (
    document.readyState === 'interactive' ||
    document.readyState === 'complete'
  ) {
    callback(null)
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback)
  } else if (document.attachEvent) {
    document.attachEvent('onreadystatechange', function (event) {
      if (document.readyState != 'loading') {
        callback(event)
      }
    })
  }
  ;(events || []).forEach((event) => {
    if (document.addEventListener) {
      document.addEventListener(event, callback)
    } else if (document.attachEvent) {
      document.attachEvent(event, callback)
    }
  })
}
